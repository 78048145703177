const getEventInfo = (isRetired, type, link, needsJSON = false) => {
  let extractedLink = ""
  if (link) {
    if (link.indexOf("http") === -1 && link.indexOf("mailto:") === -1) {
      extractedLink = window.location.origin + link
    } else {
      extractedLink = link
    }
  } else {
    extractedLink = "n/a"
  }

  const eventActionType = type === "don't have a kohler email?"
  const eventFor = isRetired ? "Ko" : "email"
  const eventAction = eventActionType
    ? `insider-program-associate-discount:group code:${type}`
    : `insider-program-associate-discount:${eventFor}:${type}`
  const eventInfo = {
    eventAction,
    eventName: eventAction,
    eventType: "click",
    eventMsg: "n/a",
    eventStatus: "n/a",
    formName: "kohler insider program form",
    internalLinkName: type,
    internalLinkPosition:
      "insider-program-associate-discount:" +
      (eventActionType ? "group code form" : "form"),
    internalLinkType: "insider-program-associate-discount:" + type,
    internalLinkZoneName: "insider-program-associate-discount:overlay",
    internalLinkURL: extractedLink,
    clickInternalLinks: "true",
  }
  if (needsJSON) {
    return eventInfo
  }
  return JSON.stringify(eventInfo)
}

const addDataLayerModelClose = () => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = getState?.("page") || {}
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: "insider-program-associate-discount:group code:close",
    eventName: "insider-program-associate-discount:group code:close",
    eventMsg: "n/a",
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: "close",
    internalLinkPosition: "insider-program-associate-discount:group code form",
    internalLinkType: "insider-program-associate-discount:close",
    internalLinkURL: "n/a",
    internalLinkZoneName: "insider-program-associate-discount:overlay",
  }
  window?.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    page,
  })
}

export { getEventInfo, addDataLayerModelClose }
