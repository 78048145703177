import React, { useState, useEffect } from "react"
import Button from "@/components/core/Button/Button"
import Checkbox from "@/components/core/Checkbox/Checkbox"
import Input from "@/components/core/Input/Input"
import ReCaptcha from "@/components/core/ReCaptcha/ReCaptcha"

import { entityToCharacter } from "@/components/FriendsAndFamily/v1/friendsAndFamilyHelper"

import { validateField, sanitizeInnerHtml } from "@/utils/helper"
import {
  addSubmitAnalytics,
  getEventInfo,
  getRecaptchaAnalytics,
} from "@/components/FriendsAndFamily/v1/FriendsAndFamilyAnalytics"
import friendsAndFamilyStyle from "@/components/FriendsAndFamily/v1/FriendsAndFamilyView/index.module.scss"

const FriendsAndFamilyView = props => {
  const {
    showModal = false,
    i18n = {},
    onSubmit = () => {},
    loading = false,
    legalPages = {},
  } = props
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [groupCode, setGroupCode] = useState("")
  const [agreeTerms, setAgreeTerms] = useState({
    terms: false,
    kbSubscription: false,
  })

  const [firstNameError, setFirstNameError] = useState({
    show: false,
    message: "",
  })
  const [lastNameError, setLastNameError] = useState({
    show: false,
    message: "",
  })
  const [emailError, setEmailError] = useState({ show: false, message: "" })
  const [zipcodeError, setZipcodeError] = useState({ show: false, message: "" })
  const [groupCodeError, setGroupCodeError] = useState({
    show: false,
    message: "",
  })
  const [error, setError] = useState({ show: false, message: "" })

  const [isAnchorEventLoaded, setAnchorEventLoaded] = useState(false)

  const {
    codeInvalidError = "",
    firstNameError: i18nFirstNameError = "",
    lastNameError: i18nLastNameError = "",
    emailError: i18nEmailError = "",
    zipcodeError: i18nZipCodeError = "",
    validZipcode = "",
    groupCodeError: i18nGroupCodeError = "",
    termsError = "",
    name: i18nName = "",
    description = "",
    description1 = "",
    firstName: i18nFirstName = "",
    lastName: i18nLastName = "",
    email: i18nEmail = "",
    code = "",
    zipcode: i18nZipCode = "",
    helpContact = "",
    termsAndConditions = "",
    getIdeas = "",
    submit = "",
    privacyPolicy: i18nPrivacyPolicy = "",
    subjectAccessRequest: i18nSubjectAccessRequest = "",
    termsOfService: i18nTermsOfService = "",
  } = i18n

  const {
    privacyPolicy = "",
    termsOfService = "",
    subjectAccessRequest = "",
  } = legalPages

  useEffect(() => {
    if (!showModal) {
      setFirstName("")
      setLastName("")
      setEmail("")
      setZipcode("")
      setGroupCode("")
      setAgreeTerms(false)
      clearError()
    } else if (!isAnchorEventLoaded) {
      // we need click event on dangerouslySetInnerHTML={{__html: i18n.need}} children. i.e. anchor tag
      setTimeout(() => {
        findElementAndAddEvent(
          "ff-membership__help-contact1",
          "friends and family support"
        )
        findElementAndAddEvent("ff-membership__terms1", "terms and conditions")
      }, 0)
    }
  }, [showModal])

  const findElementAndAddEvent = (classes, type) => {
    const descriptionElm = document.getElementsByClassName(classes)
    if (descriptionElm?.length) {
      for (let i = 0; i < descriptionElm.length; i++) {
        const aElm = descriptionElm[i].getElementsByTagName("a")
        for (const element of aElm) {
          const actualElm = element
          if (!actualElm.hasAttribute("data-gbh-data-layer")) {
            actualElm.classList.add("gbh-data-layer")
            actualElm.setAttribute(
              "data-gbh-data-layer",
              getEventInfo(type, actualElm.getAttribute("href"))
            )
            if (!isAnchorEventLoaded) {
              setAnchorEventLoaded(true)
            }
          }
        }
      }
    }
  }

  const clearError = () => {
    setFirstNameError({ show: false, message: "" })
    setLastNameError({ show: false, message: "" })
    setEmailError({ show: false, message: "" })
    setZipcodeError({ show: false, message: "" })
    setGroupCodeError({ show: false, message: "" })
  }

  const handleSuccess = () => {
    setGroupCode("")
    setAgreeTerms({ terms: false, kbSubscription: false })
    addSubmitAnalytics("success", "", groupCode, agreeTerms)
  }

  const handleTermsPolicy = e => {
    setAgreeTerms({ ...agreeTerms, terms: e.target.checked })
    if (e.target.checked) {
      setError(false)
    }
  }

  const handleError = () => {
    setGroupCodeError({ show: true, message: codeInvalidError })
    addSubmitAnalytics("failure", codeInvalidError, groupCode, agreeTerms)
  }

  const handleSubmit = e => {
    e.preventDefault()
    clearError()
    // timeout is required for ADA readout properly
    setTimeout(() => {
      const errorMessage = []
      const getElementIdList = []
      let valid = true
      if (!firstName || !validateField("name", firstName)) {
        getElementIdList.push("firstName")
        setFirstNameError({ show: true, message: i18nFirstNameError })
        valid = false
        errorMessage.push(i18nFirstNameError)
      }
      if (!lastName || !validateField("name", lastName)) {
        getElementIdList.push("lastName")
        setLastNameError({ show: true, message: i18nLastNameError })
        valid = false
        errorMessage.push(i18nLastNameError)
      }
      if (!email || !validateField("email", email)) {
        getElementIdList.push("email")
        setEmailError({ show: true, message: i18nEmailError })
        valid = false
        errorMessage.push(i18nEmailError)
      }
      if (!zipcode) {
        getElementIdList.push("zipCode")
        setZipcodeError({ show: true, message: i18nZipCodeError })
        valid = false
        errorMessage.push(i18nZipCodeError)
      } else if (!validateField("zipcode", zipcode)) {
        getElementIdList.push("zipCode")
        setZipcodeError({ show: true, message: validZipcode })
        valid = false
        errorMessage.push(validZipcode)
      }
      if (!groupCode) {
        getElementIdList.push("groupCode")
        setGroupCodeError({ show: true, message: i18nGroupCodeError })
        valid = false
        errorMessage.push(i18nGroupCodeError)
      }
      if (!agreeTerms.terms) {
        setError({ show: true, message: termsError })
        valid = false
        errorMessage.push(termsError)
      }
      if (!valid) {
        // Focus on the first error field
        addSubmitAnalytics(
          "failure",
          errorMessage.join("|").toLowerCase(),
          groupCode,
          agreeTerms
        )
        document.getElementById(getElementIdList[0])?.focus()
        document.getElementById(`${getElementIdList[0]}-error`)?.focus()
      } else {
        onSubmit(
          {
            firstName,
            lastName,
            email,
            zipcode,
            groupCode,
            kbSubscription: agreeTerms.kbSubscription,
          },
          handleSuccess,
          handleError,
          addSubmitAnalytics
        )
      }
    }, 100)
  }

  return (
    <div className={`${friendsAndFamilyStyle?.friendsAndFamilyStyleWrapper}`}>
      <div className="ff-membership">
        <div className="ff-membership__header">
          <div id="ff-modal-title" className="ff-membership__title">
            {entityToCharacter(i18nName)}
          </div>
        </div>
        <div id="ff-modal-description" className="ff-membership__description">
          {description}
        </div>
        <div className="ff-membership__description1">{description1}</div>
        <Input
          id="firstName"
          type="text"
          value={firstName}
          maxLength={40}
          placeholder={`${i18nFirstName}*`}
          label={`${i18nFirstName}*`}
          onChange={e => {
            clearError()
            setFirstName(e.target.value)
          }}
          showError={firstNameError.show}
          errorMessage={firstNameError.message}
        />
        <Input
          id="lastName"
          type="text"
          value={lastName}
          maxLength={40}
          placeholder={`${i18nLastName}*`}
          label={`${i18nLastName}*`}
          onChange={e => {
            clearError()
            setLastName(e.target.value)
          }}
          showError={lastNameError.show}
          errorMessage={lastNameError.message}
        />
        <Input
          id="email"
          type="text"
          value={email}
          maxLength={40}
          placeholder={`${i18nEmail}*`}
          label={`${i18nEmail}*`}
          onChange={e => {
            clearError()
            setEmail(e.target.value)
          }}
          showError={emailError.show}
          errorMessage={emailError.message}
        />
        <Input
          id="zipcode"
          type="text"
          value={zipcode}
          maxLength={7}
          placeholder={`${i18nZipCode}*`}
          label={`${i18nZipCode}*`}
          onChange={e => {
            clearError()
            setZipcode(e.target.value)
          }}
          showError={zipcodeError.show}
          errorMessage={zipcodeError.message}
        />
        <Input
          id="groupCode"
          type="text"
          value={groupCode}
          maxLength={40}
          placeholder={`${code}*`}
          label={`${code}*`}
          onChange={e => {
            clearError()
            setGroupCode(e.target.value.toUpperCase())
          }}
          showError={groupCodeError.show}
          errorMessage={groupCodeError.message}
        />
        <div className="ff-membership__help-contact ff-membership__help-contact1">
          {sanitizeInnerHtml(helpContact)}
        </div>
        <div className="ff-membership__terms ff-membership__terms1">
          <Checkbox
            checked={agreeTerms.terms}
            onChange={e => handleTermsPolicy(e)}
          />
          <div>{sanitizeInnerHtml(termsAndConditions)}</div>
        </div>
        {error.show && (
          <div className="key-specifier__tnc">{error.message}</div>
        )}
        <div className="ff-membership__terms">
          <Checkbox
            checked={agreeTerms.kbSubscription}
            onChange={e =>
              setAgreeTerms({ ...agreeTerms, kbSubscription: e.target.checked })
            }
          />
          {getIdeas}
        </div>
        <div className="ff-membership__help-contact ff-membership__help-contact1">
          {sanitizeInnerHtml(helpContact)}
        </div>
        <div className="ff-membership__button">
          <Button
            type="black"
            role="button"
            label={submit}
            loading={loading}
            flexible
            onClick={handleSubmit}
          />
        </div>
        <ReCaptcha getAnalyticsData={getRecaptchaAnalytics} />
        <div className="ff-membership__policies">
          <a
            href={privacyPolicy}
            className="gbh-data-layer"
            data-gbh-data-layer={getEventInfo("privacy policy", privacyPolicy)}
          >
            {i18nPrivacyPolicy}
          </a>
          <a
            href={subjectAccessRequest}
            className="gbh-data-layer"
            data-gbh-data-layer={getEventInfo(
              "subject access request",
              subjectAccessRequest
            )}
          >
            {i18nSubjectAccessRequest}
          </a>
          <a
            id="ff-last-element"
            href={termsOfService}
            className="gbh-data-layer"
            data-gbh-data-layer={getEventInfo(
              "terms of service",
              termsOfService
            )}
          >
            {i18nTermsOfService}
          </a>
        </div>
      </div>
    </div>
  )
}

export default FriendsAndFamilyView
